<template>
    <section id="bannerHeadEquipment" class="bg--img--banner--home">
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-equipment-header.jpg')"
            class="white--text "
        >
        </v-img>
    </section>
</template>

<script>
export default {
    name: 'SectionHomeHeader',
}
</script>

<style lang="scss">
</style>